.catalogue-card {
    display: flex;
    min-width: var(--width-xxs, 320px);
    padding: var(--spacing-2xl, 20px);
    align-items: center;
    gap: var(--spacing-lg, 12px);
    flex: 1 0 0;
    border-radius: var(--radius-xl, 12px);
    border: 1px solid var(--Colors-Border-border-secondary, #EAECF0);
    background: var(--Colors-Background-bg-primary, #FFF);
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    transition: transform 0.3s ease;
    position: relative;

    @media (max-width:768px) {
        min-width: 0;
    }

    &:hover {
        transform: scale(0.95);
    }

    .img-container {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 11.977px 11.539px 12.023px 12.461px;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-lg, 10px);
        border: 1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .catalogue-heading {
        color: var(--colors-text-text-secondary-700, #344054);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;

        @media (max-width:768px) {
            font-size: 14px;
        }

        /* 150% */
    }

    .card-icon {
        position: absolute;
        right: 0;
        padding-right: 20px;
    }
}