.category-container {
    display: flex;
    width: 448px;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 16px;
    background: #fff;
    padding: 16px;
    // margin: auto;

    .container {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 15px;

        p {
            color: #000;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .button-container {
            display: flex;
            gap: 14px;
            justify-content: center;

            button {
                display: flex;
                //width: 96px;
                // padding: 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: var(--radius-md, 8px);
                border: 1px solid #EAEAEA;
                background-color: #fff;
                transition: background-color 0.3s ease, color 0.3s ease;
                padding: 10px;

                &:hover {
                    
                    background: #135ADE;
                    border: 1px solid #135ADE;
                    border-radius: var(--radius-md, 8px);
                    span{
                        color: #fff;
                    }

                }

                span {
                    color: #000;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                }
            }
        }
    }
}