/* Template: Juno - Multipurpose Landing Page Pack
   Author: InovatikThemes
   Created: Mar 2019
   Description: Master CSS file
*/

/*****************************************
Table Of Contents:

01. General Styles
02. Preloader
03. Navigation
04. Header
05. Features
******************************************/

/*****************************************
Colors:

- Text, navbar links - white #f1f1f8
- Buttons, bullets, icons - turquoise #00c9db
- Navbar - navy #4633af
- Backgrounds - dark denim #262431
- Backgrounds - denim #2f2c3d
******************************************/


/******************************/
/*     01. General Styles     */
/******************************/
.InfoHome {
	*,p {
		color: #f1f1f8; 
		/* font: 400 0.875rem/1.375rem "Open Sans", sans-serif; */
	}

	.p-large {
		color: #f1f1f8;
		font: 400 1rem/1.5rem "Open Sans", sans-serif;
	}

	.p-small {
		color: #f1f1f8;
		font: 400 0.75rem/1.25rem "Open Sans", sans-serif;
	}

	.p-heading {
		margin-bottom: 3.75rem;
		text-align: center;
	}

	.li-space-lg li {
		margin-bottom: 0.375rem;
	}

	.indent {
		padding-left: 1.25rem;
	}

	h1 {
		color: #f1f1f8;
		font: 700 2.5rem/2.875rem "Montserrat", sans-serif;
	}

	h2 {
		color: #f1f1f8;
		font: 700 2.25rem/2.75rem "Montserrat", sans-serif;
	}

	h3 {
		color: #f1f1f8;
		font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
	}

	h4 {
		color: #f1f1f8;
		font: 700 1.375rem/1.75rem "Montserrat", sans-serif;
	}

	h5 {
		color: #f1f1f8;
		font: 700 1.125rem/1.625rem "Montserrat", sans-serif;
	}

	h6 {
		color: #f1f1f8;
		font: 700 1rem/1.5rem "Montserrat", sans-serif;
	}

	a {
		color: #f1f1f8;
		text-decoration: underline;
	}

	a:hover {
		color: #f1f1f8;
		text-decoration: underline;
	}

	a.turquoise {
		color: #00c9db;
	}

	a.white {
		color: #fff;
	}

	.testimonial-text {
		font: italic 400 1rem/1.5rem "Open Sans", sans-serif;
	}

	.testimonial-author {
		font: 700 1rem/1.5rem "Montserrat", sans-serif;
	}

	.btn-solid-reg {
		display: inline-block;
		padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
		border: 0.125rem solid #00c9db;
		border-radius: 2rem;
		background-color: #00c9db;
		color: #fff;
		font: 700 0.75rem/0 "Montserrat", sans-serif;
		text-decoration: none;
		transition: all 0.2s;
	}

	.btn-solid-reg:hover {
		background-color: transparent;
		color: #00c9db;
		text-decoration: none;
	}

	.btn-solid-lg {
		display: inline-block;
		padding: 1.375rem 2.625rem 1.375rem 2.625rem;
		border: 0.125rem solid #00c9db;
		border-radius: 2rem;
		background-color: #00c9db;
		color: #fff;
		font: 700 0.75rem/0 "Montserrat", sans-serif;
		text-decoration: none;
		transition: all 0.2s;
	}

	.btn-solid-lg:hover {
		background-color: transparent;
		color: #00c9db;
		text-decoration: none;
	}

	.btn-solid-lg .fab {
		margin-right: 0.5rem;
		font-size: 1.25rem;
		line-height: 0;
		vertical-align: top;
	}

	.btn-solid-lg .fab.fa-google-play {
		font-size: 1rem;
	}

	.btn-outline-reg {
		display: inline-block;
		padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
		border: 0.125rem solid #00c9db;
		border-radius: 2rem;
		background-color: transparent;
		color: #00c9db;
		font: 700 0.75rem/0 "Montserrat", sans-serif;
		text-decoration: none;
		transition: all 0.2s;
	}

	.btn-outline-reg:hover {
		background-color: #00c9db;
		color: #fff;
		text-decoration: none;
	}

	.btn-outline-lg {
		display: inline-block;
		padding: 1.375rem 2.625rem 1.375rem 2.625rem;
		border: 0.125rem solid #00c9db;
		border-radius: 2rem;
		background-color: transparent;
		color: #00c9db;
		font: 700 0.75rem/0 "Montserrat", sans-serif;
		text-decoration: none;
		transition: all 0.2s;
	}

	.btn-outline-lg:hover {
		background-color: #00c9db;
		color: #fff;
		text-decoration: none;
	}

	.btn-outline-sm {
		display: inline-block;
		padding: 1rem 1.625rem 0.875rem 1.625rem;
		border: 0.125rem solid #00c9db;
		border-radius: 2rem;
		background-color: transparent;
		color: #00c9db;
		font: 700 0.625rem/0 "Montserrat", sans-serif;
		text-decoration: none;
		transition: all 0.2s;
	}

	.btn-outline-sm:hover {
		background-color: #00c9db;
		color: #fff;
		text-decoration: none;
	}

	.form-group {
		position: relative;
		margin-bottom: 1.25rem;
	}

	.form-group.has-error.has-danger {
		margin-bottom: 0.625rem;
	}

	.form-group.has-error.has-danger .help-block.with-errors ul {
		margin-top: 0.375rem;
	}

	.label-control {
		position: absolute;
		top: 0.87rem;
		left: 1.375rem;
		color: #f1f1f8;
		opacity: 1;
		font: 400 0.875rem/1.375rem "Open Sans", sans-serif;
		cursor: text;
		transition: all 0.2s ease;
	}

	/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
		.label-control {
			top: 0.9375rem;
		}
	}

	.form-control-input:focus + .label-control,
	.form-control-input.notEmpty + .label-control,
	.form-control-textarea:focus + .label-control,
	.form-control-textarea.notEmpty + .label-control {
		top: 0.125rem;
		opacity: 1;
		font-size: 0.75rem;
		font-weight: 700;
	}

	.form-control-input,
	.form-control-select {
		display: block; /* needed for proper display of the label in Firefox, IE, Edge */
		width: 100%;
		padding-top: 1.0625rem;
		padding-bottom: 0.0625rem;
		padding-left: 1.3125rem;
		border: 1px solid #9791ae;
		border-radius: 0.25rem;
		background-color: #413d52;
		color: #f1f1f8;
		font: 400 0.875rem/1.875rem "Open Sans", sans-serif;
		transition: all 0.2s;
		-webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
	}

	.form-control-select {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		height: 3rem;
	}

	/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
		.form-control-input {
			padding-top: 1.25rem;
			padding-bottom: 0.75rem;
			line-height: 1.75rem;
		}

		.form-control-select {
			padding-top: 0.875rem;
			padding-bottom: 0.75rem;
			height: 3.125rem;
			line-height: 2.125rem;
		}
	}

	select {
		/* you should keep these first rules in place to maintain cross-browser behavior */
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
		background-image: url('../images/down-arrow.png');
		background-position: 96% 50%;
		background-repeat: no-repeat;
		outline: none;
	}

	select::-ms-expand {
		display: none; /* removes the ugly default down arrow on select form field in IE11 */
	}

	.form-control-textarea {
		display: block; /* used to eliminate a bottom gap difference between Chrome and IE/FF */
		width: 100%;
		height: 8rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
		padding-top: 1.25rem;
		padding-left: 1.3125rem;
		border: 1px solid #9791ae;
		border-radius: 0.25rem;
		background-color: #413d52;
		color: #f1f1f8;
		font: 400 0.875rem/1.75rem "Open Sans", sans-serif;
		transition: all 0.2s;
	}

	.form-control-input:focus,
	.form-control-select:focus,
	.form-control-textarea:focus {
		border: 1px solid #f1f1f8;
		outline: none; /* Removes blue border on focus */
	}

	.form-control-input:hover,
	.form-control-select:hover,
	.form-control-textarea:hover {
		border: 1px solid #f1f1f8;
	}

	.checkbox {
		font: 400 0.75rem/1.25rem "Open Sans", sans-serif;
	}

	input[type='checkbox'] {
		vertical-align: -15%;
		margin-right: 0.375rem;
	}

	/* IE10+ hack to raise checkbox field position compared to the rest of the browsers */
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
		input[type='checkbox'] {
			vertical-align: -9%;
		}
	}

	.form-control-submit-button {
		display: inline-block;
		width: 100%;
		height: 3.125rem;
		border: 1px solid #00c9db;
		border-radius: 1.5rem;
		background-color: #00c9db;
		color: #fff;
		font: 700 0.75rem/1.75rem "Montserrat", sans-serif;
		cursor: pointer;
		transition: all 0.2s;
	}

	.form-control-submit-button:hover {
		background-color: transparent;
		color: #00c9db;
	}

	/* Form Success And Error Message Formatting */
	#cmsgSubmit.h3.text-center.tada.animated,
	#pmsgSubmit.h3.text-center.tada.animated,
	#cmsgSubmit.h3.text-center,
	#pmsgSubmit.h3.text-center {
		display: block;
		margin-bottom: 0;
		color: #f1f1f8;
		font: 400 1.125rem/1rem "Open Sans", sans-serif;
	}

	.help-block.with-errors .list-unstyled {
		color: #f1f1f8;
		font-size: 0.75rem;
		line-height: 1.125rem;
		text-align: left;
	}

	.help-block.with-errors ul {
		margin-bottom: 0;
	}
	/* end of form success and error message formatting */

	/* Form Success And Error Message Animation - Animate.css */
	@-webkit-keyframes tada {
		from {
			-webkit-transform: scale3d(1, 1, 1);
			-ms-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
		10%, 20% {
			-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
			-ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
			transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		}
		30%, 50%, 70%, 90% {
			-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
			-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
			transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		}
		40%, 60%, 80% {
			-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
			-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
			transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		}
		to {
			-webkit-transform: scale3d(1, 1, 1);
			-ms-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
	}

	@keyframes tada {
		from {
			-webkit-transform: scale3d(1, 1, 1);
			-ms-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
		10%, 20% {
			-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
			-ms-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
			transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		}
		30%, 50%, 70%, 90% {
			-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
			-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
			transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		}
		40%, 60%, 80% {
			-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
			-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
			transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		}
		to {
			-webkit-transform: scale3d(1, 1, 1);
			-ms-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
	}

	.tada {
		-webkit-animation-name: tada;
		animation-name: tada;
	}

	.animated {
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	/* end of form success and error message animation - Animate.css */

	/* Fade-move Animation For Lightbox - Magnific Popup */
	/* at start */
	.my-mfp-slide-bottom .zoom-anim-dialog {
		opacity: 0;
		transition: all 0.2s ease-out;
		-webkit-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
		-ms-transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
		transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
	}

	/* animate in */
	.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
		opacity: 1;
		-webkit-transform: translateY(0) perspective(37.5rem) rotateX(0); 
		-ms-transform: translateY(0) perspective(37.5rem) rotateX(0); 
		transform: translateY(0) perspective(37.5rem) rotateX(0); 
	}

	/* animate out */
	.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
		opacity: 0;
		-webkit-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg); 
		-ms-transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg); 
		transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg); 
	}

	/* dark overlay, start state */
	.my-mfp-slide-bottom.mfp-bg {
		opacity: 0;
		transition: opacity 0.2s ease-out;
	}

	/* animate in */
	.my-mfp-slide-bottom.mfp-ready.mfp-bg {
		opacity: 0.8;
	}
	/* animate out */
	.my-mfp-slide-bottom.mfp-removing.mfp-bg {
		opacity: 0;
	}
	/* end of fade-move animation for lightbox - magnific popup */

	/* Fade Animation For Image Slider - Magnific Popup */
	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.fadeIn {
		-webkit-animation: fadeIn 0.6s;
		animation: fadeIn 0.6s;
	}

	@-webkit-keyframes fadeOut {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	@keyframes fadeOut {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	.fadeOut {
		-webkit-animation: fadeOut 0.8s;
		animation: fadeOut 0.8s;
	}
	/* end of fade animation for image slider - magnific popup */


	/*************************/
	/*     02. Preloader     */
	/*************************/
	.spinner-wrapper {
		position: fixed;
		z-index: 999999;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #2f2c3d;
	}

	.spinner {
		position: absolute;
		top: 50%; /* centers the loading animation vertically one the screen */
		left: 50%; /* centers the loading animation horizontally one the screen */
		width: 3.75rem;
		height: 1.25rem;
		margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */ 
		text-align: center;
	}

	.spinner > div {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		border-radius: 100%;
		background-color: #fff;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.spinner .bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.spinner .bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}

	@-webkit-keyframes sk-bouncedelay {
		0%, 80%, 100% { -webkit-transform: scale(0); }
		40% { -webkit-transform: scale(1.0); }
	}

	@keyframes sk-bouncedelay {
		0%, 80%, 100% { 
			-webkit-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
		} 40% { 
			-webkit-transform: scale(1.0);
			-ms-transform: scale(1.0);
			transform: scale(1.0);
		}
	}


	/**************************/
	/*     03. Navigation     */
	/**************************/
	.navbar-custom {
		background-color: #4633af;
		box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
		font: 700 0.75rem/2rem "Montserrat", sans-serif;
		transition: all 0.2s;
	}

	.navbar-custom .navbar-brand.logo-image img {
		width: 113px;
		height: 34px;
		margin-bottom: 1px;
		-webkit-backface-visibility: hidden;
	}

	.navbar-custom .navbar-brand.logo-text {
		font: 700 2.375rem/1.5rem "Montserrat", sans-serif;
		color: #fff;
		letter-spacing: -0.5px;
		text-decoration: none;
	}

	.navbar-custom .navbar-nav {
		margin-top: 0.75rem;
	}

	.navbar-custom .nav-item .nav-link {
		padding: 0 0.75rem 0 0.75rem;
		color: #fff;
		text-decoration: none;
		transition: all 0.2s ease;
	}

	.navbar-custom .nav-item .nav-link:hover {
		color: #00c9db;
	}

	/* Dropdown Menu */
	.navbar-custom .dropdown:hover > .dropdown-menu {
		display: block; /* this makes the dropdown menu stay open while hovering it */
		min-width: auto;
		animation: fadeDropdown 0.2s; /* required for the fade animation */
	}

	@keyframes fadeDropdown {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.navbar-custom .dropdown-toggle:focus { /* removes dropdown outline on focus  */
		outline: 0;
	}

	.navbar-custom .dropdown-menu {
		margin-top: 0;
		border: none;
		border-radius: 0.25rem;
		background-color: #4633af;
	}

	.navbar-custom .dropdown-item {
		color: #fff;
		text-decoration: none;
	}

	.navbar-custom .dropdown-item:hover {
		background-color: #4633af;
	}

	.navbar-custom .dropdown-item .item-text {
		font: 700 0.75rem/1.5rem "Montserrat", sans-serif;
	}

	.navbar-custom .dropdown-item:hover .item-text {
		color: #00c9db;
	}

	.navbar-custom .dropdown-items-divide-hr {
		width: 100%;
		height: 1px;
		margin: 0.25rem auto 0.25rem auto;
		border: none;
		background-color: #b5bcc4;
		opacity: 0.2;
	}
	/* end of dropdown menu */

	.navbar-custom .social-icons {
		display: none;
	}

	.navbar-custom .navbar-toggler {
		border: none;
		color: #fff;
		font-size: 2rem;
	}

	.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
		display: none;
	}

	.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
		display: inline-block;
	}

	.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
		display: none;
	}

	.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
		display: inline-block;
		margin-right: 0.125rem;
	}


	/*********************/
	/*    04. Header     */
	/*********************/
	.header {
		background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('../images/header-background.jpg') center center no-repeat;
		background-size: cover;
	}

	.header .header-content {
		padding-top: 8.5rem;
		padding-bottom: 7rem;
		text-align: center;
	}

	.header .text-container {
		margin-bottom: 3rem;
	}

	.header h1 {
		margin-bottom: 1rem;
	}

	.header #js-rotating {
		color: #40e0ee;
	}

	.header .p-large {
		margin-bottom: 2rem;
	}

	.header .btn-solid-lg {
		margin-right: 0.5rem;
		margin-bottom: 1.25rem;
	}


	/****************************/
	/*     05. Testimonials     */
	/****************************/
	.slider-1 {
		padding-top: 6.875rem;
		padding-bottom: 6.375rem;
		background-color: #262431;
	}

	.slider-1 .slider-container {
		position: relative;
	}

	.slider-1 .swiper-container {
		position: static;
		width: 90%;
		text-align: center;
	}

	.slider-1 .swiper-button-prev:focus,
	.slider-1 .swiper-button-next:focus {
		/* even if you can't see it chrome you can see it on mobile device */
		outline: none;
	}

	.slider-1 .swiper-button-prev {
		left: -0.5rem;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23f1f1f8'%2F%3E%3C%2Fsvg%3E");
		background-size: 1.125rem 1.75rem;
	}

	.slider-1 .swiper-button-next {
		right: -0.5rem;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23f1f1f8'%2F%3E%3C%2Fsvg%3E");
		background-size: 1.125rem 1.75rem;
	}

	.slider-1 .card {
		position: relative;
		border: none;
		background-color: transparent;
	}

	.slider-1 .card-image {
		width: 6rem;
		height: 6rem;
		margin-right: auto;
		margin-bottom: 0.25rem;
		margin-left: auto;
		border-radius: 50%;
	}

	.slider-1 .card-body {
		padding-bottom: 0;
	}

	.slider-1 .testimonial-author {
		margin-bottom: 0;
	}


	/************************/
	/*     05. Features     */
	/************************/
	.tabs {
		padding-top: 6.5rem;
		padding-bottom: 4.25rem;
		background-color: #2f2c3d;
	}

	.tabs h2 {
		margin-bottom: 1.125rem;
		text-align: center;
	}

	.tabs .p-heading {
		margin-bottom: 3.125rem;
	}

	.tabs .nav-tabs {
		margin-right: auto;
		margin-bottom: 2.5rem;
		margin-left: auto;
		justify-content: center;
		border-bottom: none;
	}

	.tabs .nav-link {
		margin-bottom: 1rem;
		padding: 0.5rem 1.375rem 0.25rem 1.375rem;
		border: none;
		border-bottom: 0.1875rem solid #f1f1f8;
		border-radius: 0;
		color: #f1f1f8;
		font: 700 1rem/1.75rem "Montserrat", sans-serif;
		text-decoration: none;
		transition: all 0.2s ease;
	}

	.tabs .nav-link.active,
	.tabs .nav-link:hover {
		border-bottom: 0.1875rem solid #00c9db;
		background-color: transparent;
		color: #00c9db;
	}

	.tabs .nav-link .fas {
		margin-right: 0.375rem;
		font-size: 1rem;
	}

	.tabs .tab-content {
		width: 100%; /* for proper display in IE11 */
	}

	.tabs .card {
		border: none;
		background: transparent;
	}

	.tabs .card-body {
		padding: 1rem 0 1.25rem 0;
	}

	.tabs .card-title {
		margin-bottom: 0.5rem;
	}

	.tabs .card .card-icon {
		display: inline-block;
		width: 3.5rem;
		height: 3.5rem;
		border-radius: 50%;
		background-color: #00c9db;
		text-align: center;
		vertical-align: top;
	}

	.tabs .card .card-icon .fas,
	.tabs .card .card-icon .far {
		color: #fff;
		font-size: 1.75rem;
		line-height: 3.5rem;
	}

	.tabs #tab-1 .card.left-pane .text-wrapper {
		display: inline-block;
		width: 75%;
	}

	.tabs #tab-1 .card.left-pane .card-icon {
		float: left;
		margin-right: 1rem;
	}

	.tabs #tab-1 img {
		display: block;
		margin: 2rem auto 3rem auto;
	}

	.tabs #tab-1 .card.right-pane .text-wrapper {
		display: inline-block;
		width: 75%;
	}

	.tabs #tab-1 .card.right-pane .card-icon {
		margin-right: 1rem;
	}

	.tabs #tab-2 img {
		display: block;
		margin: 0 auto 2rem auto;
	}

	.tabs #tab-2 .text-area {
		margin-top: 1.5rem;
	}

	.tabs #tab-2 h3 {
		margin-bottom: 0.75rem;
	}

	.tabs #tab-2 .icon-cards-area {
		margin-top: 2.5rem;
	}

	.tabs #tab-2 .icon-cards-area .card {
		width: 100%; /* for proper display in IE11 */
	}

	.tabs #tab-3 .icon-cards-area .card {
		width: 100%; /* for proper display in IE11 */
	}

	.tabs #tab-3 .text-area {
		margin-top: 0.75rem;
		margin-bottom: 4rem;
	}

	.tabs #tab-3 h3 {
		margin-bottom: 0.75rem;
	}

	.tabs #tab-3 img {
		margin: 0 auto 3rem auto;
	}


	/*********************/
	/*     07. Video     */
	/*********************/
	.basic-1 {
		padding-top: 6.375rem;
		padding-bottom: 6.875rem;
		background: url('../images/video-background.jpg') center center no-repeat;
		background-size: cover; 
	}

	.basic-1 h2 {
		margin-bottom: 1.125rem;
		text-align: center;
	}

	.basic-1 .p-heading {
		margin-bottom: 4rem;
		text-align: center;
	}

	.basic-1 .image-container img {
		border-radius: 0.375rem;
	}

	.basic-1 .video-wrapper {
		position: relative;
	}

	/* Video Play Button */
	.basic-1 .video-play-button {
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		display: block;
		box-sizing: content-box;
		width: 2rem;
		height: 2.75rem;
		padding: 1.125rem 1.25rem 1.125rem 1.75rem;
		border-radius: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	
	.basic-1 .video-play-button:before {
		content: "";
		position: absolute;
		z-index: 0;
		top: 50%;
		left: 50%;
		display: block;
		width: 4.75rem;
		height: 4.75rem;
		border-radius: 50%;
		background: #4eaaff;
		animation: pulse-border 1500ms ease-out infinite;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	
	.basic-1 .video-play-button:after {
		content: "";
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		display: block;
		width: 4.375rem;
		height: 4.375rem;
		border-radius: 50%;
		background: #4eaaff;
		transition: all 200ms;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
	
	.basic-1 .video-play-button span {
		position: relative;
		display: block;
		z-index: 3;
		top: 0.375rem;
		left: 0.25rem;
		width: 0;
		height: 0;
		border-left: 1.625rem solid #fff;
		border-top: 1rem solid transparent;
		border-bottom: 1rem solid transparent;
	}
	
	@keyframes pulse-border {
		0% {
			transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
			opacity: 1;
		}
		100% {
			transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
			opacity: 0;
		}
	}
	/* end of video play button */  


	/*************************/
	/*     09. Details 1     */
	/*************************/
	.basic-2 {
		padding-top: 8rem;
		padding-bottom: 3.5rem;
		background-color: #262431;
	}

	.basic-2 img {
		margin-bottom: 3.5rem;
	}

	.basic-2 h3 {
		margin-bottom: 1.125rem;
	}

	.basic-2 .btn-solid-reg {
		margin-top: 0.5rem;
	}


	/*************************/
	/*     10. Details 2     */
	/*************************/
	.basic-3 {
		padding-top: 3.5rem;
		padding-bottom: 7.25rem;
		background-color: #262431;
	}

	.basic-3 .text-container {
		margin-bottom: 3.5rem;
	}

	.basic-3 h3 {
		margin-bottom: 1.125rem;
	}

	.basic-3 .btn-solid-reg {
		margin-top: 0.5rem;
	}


	/**********************************/
	/*     11. Details Lightboxes     */
	/**********************************/
	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
		z-index: 9998; /* Below modal content */
		 /* Hidden by default */
	  }
	  
	  /* Modal container */
	  .lightbox-basic {
		position: fixed; /* Ensure modal is positioned relative to the viewport */
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); /* Center the modal */
		max-width: 46.875rem;
		padding: 3rem 1rem;
		border-radius: 0.25rem;
		background-color: #2f2c3d;
		text-align: left;
		z-index: 9999; /* Above overlay */
		/* Hidden by default */
	  }
	  
	  /* Keep image centered */
	  .lightbox-basic img {
		display: block;
		margin-right: auto;
		margin-bottom: 3rem;
		margin-left: auto;
	  }
	  
	  /* Close button for modal */
	  .lightbox-basic button.mfp-close.x-button {
		position: absolute;
		top: -0.375rem;
		right: -0.375rem;
		width: 2.75rem;
		height: 2.75rem;
		color: #f1f1f8;
		background: none;
		border: none;
		font-size: 1.5rem;
		cursor: pointer;
	  }
	  
	  /* Custom styles for other elements */
	  .lightbox-basic h3 {
		margin-bottom: 0.625rem;
		color: #ffffff;
	  }
	  
	  .lightbox-basic hr {
		width: 3.75rem;
		height: 0.125rem;
		margin-top: 0.125rem;
		margin-bottom: 1.125rem;
		background-color: #00c9db;
	  }
	  
	  .lightbox-basic h4 {
		margin-top: 1.75rem;
		margin-bottom: 0.75rem;
		color: #ffffff;
	  }
	  
	  .lightbox-basic table tr {
		line-height: 1.75em;
	  }
	  
	  .lightbox-basic .icon-cell {
		width: 2rem;
		padding-right: 0.25rem;
		color: #00c9db;
		text-align: center;
	  }
	  
	  .lightbox-basic a.mfp-close.as-button {
		color: #00c9db;
		text-decoration: none;
	  }
	  
	  .lightbox-basic a.mfp-close.as-button:hover {
		color: #f1f1f8;
	  }


	/***************************/
	/*     12. Screenshots     */
	/***************************/
	.slider-2 {
		padding-top: 6.875rem;
		padding-bottom: 6.875rem;
		background-color: #2f2c3d;
	}

	.slider-2 .slider-container {
		position: relative;
	}

	.slider-2 .swiper-container {
		position: static;
		width: 90%;
		text-align: center;
	}

	.slider-2 .swiper-button-prev,
	.slider-2 .swiper-button-next {
		top: 50%;
		width: 1.125rem;
	}

	.slider-2 .swiper-button-prev:focus,
	.slider-2 .swiper-button-next:focus {
		/* even if you can't see it chrome you can see it on mobile device */
		outline: none ;
	}

	.slider-2 .swiper-button-prev {
		left: -0.5rem;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
		background-size: 1.125rem 1.75rem;
	}

	.slider-2 .swiper-button-next {
		right: -0.5rem;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
		background-size: 1.125rem 1.75rem;
	}


	/************************/
	/*     13. Download     */
	/************************/
	.basic-4 {
		padding-top: 6.5rem;
		padding-bottom: 6.75rem;
		background: url('../images/download-background.jpg') center center no-repeat;
		background-size: cover; 
	}

	.basic-4 .text-container {
		margin-bottom: 3.5rem;
		text-align: center;
	}

	.basic-4 h2 {
		margin-bottom: 1.25rem;
	}

	.basic-4 .p-large {
		margin-bottom: 1.75rem;
	}

	.basic-4 .btn-solid-lg {
		margin-right: 0.5rem;
		margin-bottom: 1.25rem;
	}


	/**************************/
	/*     18. Statistics     */
	/**************************/
	.counter {
		padding-top: 6.5rem;
		padding-bottom: 5.375rem;
		background-color: #262431;
		text-align: center;
	}

	.counter #counter .cell {
		display: inline-block;
		width: 6.25rem;
		margin-right: 1rem;
		margin-left: 1rem;
		margin-bottom: 2rem;
	}

	.counter #counter .counter-value {
		color: #f1f1f8;
		font: 700 3.5rem/4.25rem "Montserrat", sans-serif;	
		vertical-align: middle;
	}

	.counter #counter .counter-info {
		margin-bottom: 0;
		color: #00c9db;
		font: 400 0.875rem/1.25rem "Open Sans", sans-serif;
		vertical-align: middle;
	}


	/***********************/
	/*     16. Contact     */
	/***********************/
	.form {
		padding-top: 6.25rem;
		padding-bottom: 5.625rem;
		background: url('../images/contact-background.jpg') center bottom no-repeat;
		background-size: cover; 
	}

	.form h2 {
		margin-bottom: 1.125rem;
		text-align: center;
	}

	.form .list-unstyled {
		margin-bottom: 3.75rem;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: center;
	}

	.form .list-unstyled .fas,
	.form .list-unstyled .fab {
		margin-right: 0.5rem;
		font-size: 0.875rem;
		color: #00c9db;
	}

	.form .list-unstyled .fa-phone {
		vertical-align: 3%;
	}


	/**********************/
	/*     16. Footer     */
	/**********************/
	.footer {
		padding-top: 5rem;
		background-color: #262431;
	}

	.footer .footer-col {
		margin-bottom: 2.25rem;
	}

	.footer h4 {
		margin-bottom: 1rem;
	}

	.footer .list-unstyled .fas {
		color: #00c9db;
		font-size: 0.5rem;
		line-height: 1.375rem;
	}

	.footer .list-unstyled .media-body {
		margin-left: 0.625rem;
	}

	.footer .fa-stack {
		margin-bottom: 0.75rem;
		margin-right: 0.5rem;
		font-size: 1.5rem;
	}

	.footer .fa-stack .fa-stack-1x {
		color: #262431;
		transition: all 0.2s ease;
	}

	.footer .fa-stack .fa-stack-2x {
		color: #f1f1f8;
		transition: all 0.2s ease;
	}

	.footer .fa-stack:hover .fa-stack-1x {
		color: #f1f1f8;
	}

	.footer .fa-stack:hover .fa-stack-2x {
		color: #00c9db;
	}


	/*************************/
	/*     17. Copyright     */
	/*************************/
	.copyright {
		padding-top: 1rem;
		padding-bottom: 0.5rem;
		background-color: #262431;
		text-align: center;
	}

	.copyright .p-small {
		color: #f1f1f8;
		opacity: 0.6;
	}


	/**********************************/
	/*     18. Back To Top Button     */
	/**********************************/
	a.back-to-top {
		position: fixed;
		z-index: 999;
		right: 0.75rem;
		bottom: 0.75rem;
		display: none;
		width: 2.625rem;
		height: 2.625rem;
		border-radius: 1.875rem;
		background: #00c9db url("../images/up-arrow.png") no-repeat center 47%;
		background-size: 1.125rem 1.125rem;
		text-indent: -9999px;
	}

	a:hover.back-to-top {
		background-color: #36edfd; 
	}


	/***************************/
	/*     19. Extra Pages     */
	/***************************/
	.ex-header {
		padding-top: 8rem;
		padding-bottom: 5rem;
		background: linear-gradient(to bottom right, #2c1a7e, #5557db);
		text-align: center;
	}

	.ex-basic-1 {
		padding-top: 2rem;
		padding-bottom: 0.875rem;
		background-color: #2f2c3d;
	}

	.ex-basic-1 .breadcrumbs {
		margin-bottom: 1.125rem;
	}

	.ex-basic-1 .breadcrumbs .fa {
		margin-right: 0.5rem;
		margin-left: 0.625rem;
	}

	.ex-basic-2 {
		padding-top: 4.75rem;
		padding-bottom: 4rem;
		background-color: #262431;
	}

	.ex-basic-2 h3 {
		margin-bottom: 1rem;
	}

	.ex-basic-2 .text-container {
		margin-bottom: 3.625rem;
	}

	.ex-basic-2 .text-container.last {
		margin-bottom: 0;
	}

	.ex-basic-2 .list-unstyled .fas {
		color: #00c9db;
		font-size: 0.5rem;
		line-height: 1.375rem;
	}

	.ex-basic-2 .list-unstyled .media-body {
		margin-left: 0.625rem;
	}

	.ex-basic-2 .btn-outline-reg {
		margin-top: 1.75rem;
	}

	.ex-basic-2 .image-container-large {
		margin-bottom: 4rem;
	}

	.ex-basic-2 .image-container-large img {
		border-radius: 0.25rem;
	}

	.ex-basic-2 .image-container-small img {
		border-radius: 0.25rem;
	}

	.ex-basic-2 .text-container.dark-bg {
		padding: 1.625rem 1.5rem 0.75rem 2rem;
		background-color: #f9fafc;
	}


	/*****************************/
	/*     20. Media Queries     */
	/*****************************/	
	/* Min-width width 768px */
	@media (min-width: 768px) {
		
		/* General Styles */
		.p-heading {
			width: 85%;
			margin-right: auto;
			margin-left: auto;
		}

		h1 {
			font: 700 3.25rem/3.75rem "Montserrat", sans-serif;
		}
		/* end of general styles */


		/* Navigation */
		.navbar-custom {
			padding: 2.125rem 1.5rem 2.125rem 2rem;
			box-shadow: none;
			background: transparent;
		}
		
		.navbar-custom .navbar-brand.logo-text {
			color: #fff;
		}

		.navbar-custom .navbar-nav {
			margin-top: 0;
		}

		.navbar-custom .nav-item .nav-link {
			padding: 0.25rem 0.75rem 0.25rem 0.75rem;
			color: #fff;
		}
		
		.navbar-custom .nav-item .nav-link:hover {
			color: #00c9db;
		}

		.navbar-custom.top-nav-collapse {
			padding: 0.5rem 1.5rem 0.5rem 2rem;
			box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
			background-color: #4633af;
		}

		.navbar-custom.top-nav-collapse .navbar-brand.logo-text {
			color: #fff;
		}

		.navbar-custom.top-nav-collapse .nav-item .nav-link {
			color: #fff;
		}
		
		.navbar-custom.top-nav-collapse .nav-item .nav-link:hover {
			color: #00c9db;
		}

		.navbar-custom .dropdown-menu {
			box-shadow: 0 0.25rem 0.375rem 0 rgba(0, 0, 0, 0.03);
		}

		.navbar-custom .dropdown-item {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}

		.navbar-custom .dropdown-items-divide-hr {
			width: 84%;
		}
		/* end of navigation */


		/* Header */
		.header .header-content {
			padding-top: 11rem;
		}
		/* end of header */


		/* Testimonials */
		.slider-1 .swiper-button-prev {
			left: 1rem;
			width: 1.375rem;
			background-size: 1.375rem 2.125rem;
		}
		
		.slider-1 .swiper-button-next {
			right: 1rem;
			width: 1.375rem;
			background-size: 1.375rem 2.125rem;
		}
		/* end of testimonials */


		/* Features */
		.tabs .card .card-icon {
			width: 4.5rem;
			height: 4.5rem;
		}
		
		.tabs .card .card-icon .fas,
		.tabs .card .card-icon .far {
			font-size: 2.25rem;
			line-height: 4.5rem;
		}

		.tabs #tab-1 .card.left-pane .text-wrapper {
			width: 85%;
		}

		.tabs #tab-2 img {
			margin-bottom: 0;
		}

		.tabs #tab-2 .text-area {
			margin-top: 0;
		}

		.tabs #tab-2 .icon-cards-area .card {
			display: inline-block;
			width: 44%;
			margin-right: 2.5rem;
			vertical-align: top;
		}

		.tabs #tab-2 div.card:nth-child(2n+2) {
			margin-right: 0;
		}

		.tabs #tab-3 .text-area {
			margin-bottom: 0;
		}

		.tabs #tab-3 .icon-cards-area .card {
			display: inline-block;
			width: 44%;
			margin-right: 2.5rem;
			vertical-align: top;
		}

		.tabs #tab-3 div.card:nth-child(2n+2) {
			margin-right: 0;
		}

		.tabs #tab-3 img {
			margin-bottom: 0;
		}
		/* end of features */


		/* Details Lightboxes */
		.lightbox-basic {
			padding: 3rem 3rem;
		}
		/* end of details lightboxes */


		/* Screenshots */
		.slider-2 .swiper-button-prev {
			width: 1.375rem;
			background-size: 1.375rem 2.125rem;
		}
		
		.slider-2 .swiper-button-next {
			width: 1.375rem;
			background-size: 1.375rem 2.125rem;
		}
		/* end of screenshots */


		/* Contact */
		.form .list-unstyled li {
			display: inline-block;
			margin-right: 0.5rem;
			margin-left: 0.5rem;
		}

		.form .list-unstyled .address {
			display: block;
		}
		/* end of contact */


		/* Extra Pages */
		.ex-header {
			padding-top: 11rem;
			padding-bottom: 9rem;
		}

		.ex-basic-2 .text-container.dark {
			padding: 2.5rem 3rem 2rem 3rem;
		}

		.ex-basic-2 .text-container.column {
			width: 90%;
			margin-right: auto;
			margin-left: auto;
		}
		/* end of extra pages */
	}
	/* end of min-width width 768px */


	/* Min-width width 992px */
	@media (min-width: 992px) {
		
		/* Navigation */
		.navbar-custom .social-icons {
			display: block;
			margin-left: 0.5rem;
		}

		.navbar-custom .fa-stack {
			margin-bottom: 0.1875rem;
			margin-left: 0.375rem;
			font-size: 0.75rem;
		}
		
		.navbar-custom .fa-stack-2x {
			color: #00c9db;
			transition: all 0.2s ease;
		}
		
		.navbar-custom .fa-stack-1x {
			color: #fff;
			transition: all 0.2s ease;
		}

		.navbar-custom .fa-stack:hover .fa-stack-2x {
			color: #fff;
		}

		.navbar-custom .fa-stack:hover .fa-stack-1x {
			color: #00c9db;
		}
		/* end of navigation */


		/* General Styles */
		.p-heading {
			width: 65%;
		}
		/* end of general styles */


		/* Header */
		.header .header-content {
			padding-top: 13rem;
			padding-bottom: 10rem;
			text-align: left;
		}

		.header .text-container {
			margin-top: 6.5rem;
		}
		/* end of header */


		/* Features */
		.tabs .card-body {
			padding: 1rem 0 1.5rem 0;
		}
		
		.tabs #tab-1 .card.left-pane {
			text-align: right;
		}

		.tabs #tab-1 .card.left-pane .text-wrapper,
		.tabs #tab-1 .card.right-pane .text-wrapper {
			width: 68%;
		}

		.tabs #tab-1 .card.left-pane .card-icon {
			float: none;
			margin-right: 0;
			margin-left: 1rem;
		}

		.tabs #tab-1 img {
			margin-top: 0;
			margin-bottom: 0;
		}
		
		.tabs #tab-2 .icon-cards-area {
			margin-top: 2.25rem;
		}

		.tabs #tab-2 .icon-cards-area .card {
			width: 45%;
			margin-right: 3.5rem;
		}

		.tabs #tab-2 .icon-cards-area .card p {
			margin-bottom: 0.5rem;
		}

		.tabs #tab-3 .icon-cards-area .card {
			width: 45%;
			margin-right: 3.5rem;
		}

		.tabs #tab-3 .icon-cards-area .card p {
			margin-bottom: 0.5rem;
		}
		/* end of features */


		/* Video */
		.basic-1 .image-container {
			max-width: 53.125rem;
			margin-right: auto;
			margin-left: auto;
		}
		/* end of video */


		/* Details 1 */
		.basic-2 img {
			margin-bottom: 0;
		}

		.basic-2 .text-container {
			margin-top: 2.625rem;
		}
		/* end of details 1 */


		/* Details 2 */
		.basic-3 .text-container {
			margin-top: 2.5rem;
			margin-bottom: 0;
		}
		/* end of details 2 */


		/* Details Lightboxes */
		.lightbox-basic img {
			margin-bottom: 0;
			margin-left: 0;
		}

		.lightbox-basic h3 {
			margin-top: 0.25rem;
		}
		/* end of details lightboxes */


		/* Screenshots */
		.slider-2 .swiper-container {
			width: 92%;
		}
		/* end of screenshots */


		/* Download */
		.basic-4 {
			padding-top: 6.75rem;
		}

		.basic-4 .text-container {
			margin-top: 7rem;
			margin-bottom: 0;
			text-align: left;
		}
		/* end of download */


		/* Statistics */
		.counter {
			padding-top: 6rem;
			padding-bottom: 4.5rem;
		}

		.counter #counter .cell {
			width: 8rem;
			margin-right: 2.5rem;
			margin-left: 2.5rem;
		}
		
		.counter #counter .counter-value {
			font: 700 4.25rem/4.5rem "Montserrat", sans-serif;	
		}
		/* end of statistics */


		/* Extra Pages */
		.ex-header h1 {
			width: 80%;
			margin-right: auto;
			margin-left: auto;
		}

		.ex-basic-2 {
			padding-bottom: 5rem;
		}

		.ex-basic-2 .text-container.column {
			margin-bottom: 0;
		}
		/* end of extra pages */
	}
	/* end of min-width width 992px */


	/* Min-width width 1200px */
	@media (min-width: 1200px) {
		
		/* Navigation */
		.navbar-custom {
			padding: 2.125rem 5rem 2.125rem 5rem;
		}

		.navbar-custom.top-nav-collapse {
			padding: 0.5rem 5rem 0.5rem 5rem;
		}
		/* end of navigation */

		
		/* General Styles */
		.p-heading {
			width: 44%;
		}
		/* end of general styles */


		/* Header */
		.header .text-container {
			margin-top: 7.5rem;
			margin-left: 2.5rem;
		}

		.header .image-container {
			margin-left: 3rem;
		}
		/* end of header */


		/* Features */
		.tabs {
			padding-bottom: 6.5rem;
		}

		.tabs #tab-1 .card.first {
			margin-top: 4.25rem;
		}

		.tabs #tab-1 .card {
			margin-bottom: 0.75rem;
		}

		.tabs #tab-1 .card.left-pane .text-wrapper,
		.tabs #tab-1 .card.right-pane .text-wrapper {
			width: 73%;
		}

		.tabs #tab-1 img {
			margin-top: 0;
		}

		.tabs #tab-2 .container {
			padding-right: 2.5rem;
			padding-left: 2.5rem;
		}

		.tabs #tab-2 .text-area {
			margin-top: 1.5rem;
			margin-right: 1rem;
			margin-left: 1rem;
		}

		.tabs #tab-2 .icon-cards-area {
			margin-right: 1rem;
			margin-left: 1rem;
		}

		.tabs #tab-2 .icon-cards-area .card {
			margin-right: 3.875rem;
		}

		.tabs #tab-3 .container {
			padding-right: 2.5rem;
			padding-left: 2.5rem;
		}
		
		.tabs #tab-3 .icon-cards-area {
			margin-top: 2rem;
			margin-left: 1rem;
		}

		.tabs #tab-3 .icon-cards-area .card {
			margin-right: 3.875rem;
		}
		
		.tabs #tab-3 .text-area {
			margin-right: 1.5rem;
			margin-left: 1rem;
		}
		/* end of features */


		/* Details 1 */
		.basic-2 .text-container {
			margin-top: 4.5rem;
			margin-left: 4rem;
			margin-right: 1.5rem;
		}
		/* end of details 1 */
		
		
		/* Details 2 */
		.basic-3 .text-container {
			margin-top: 4.5rem;
			margin-right: 3.5rem;
			margin-left: 2rem;
		}
		/* end of details 2 */


		/* Download */
		.basic-4 .text-container {
			margin-top: 11rem;
		}

		.basic-4 .image-container {
			margin-left: 3rem;
		}
		/* end of download */


		/* Footer */
		.footer .footer-col {
			width: 90%;
		}

		.footer .footer-col.middle {
			margin-right: auto;
			margin-left: auto;
		}

		.footer .footer-col.last {
			margin-right: 0;
			margin-left: auto;
		}
		/* end of footer */


		/* Extra Pages */
		.ex-header h1 {
			width: 60%;
			margin-right: auto;
			margin-left: auto;
		}

		.ex-basic-2 .form-container {
			margin-left: 1.75rem;
		}

		.ex-basic-2 .image-container-small {
			margin-left: 1.75rem;
		}
		/* end of extra pages */
	}
	/* end of min-width width 1200px */
}