@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Jacques+Francois&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Protest+Revolution&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

$primary-50: #eaf0fc;
$primary-100: #3771dd;
$primary-500: #135ade;
$primary-700: #003392;

$background: #f7f7f7;
$default-gray: #6b6a6a;

$font-family-jacques-francois: "Jacques Francois", serif;

$box-shadow-top: 0 -6px 6px -4px rgba(0, 0, 0, 0.2);
$box-shadow-left: -5px 0px 35px rgba(0, 0, 0, 0.4);

@mixin primary-btn {
  background-color: $primary-500;
  color: white;
  &:hover {
    background-color: $primary-100;
  }
  transition: background-color 0.3s;
  outline: none;
  border: none;
  border-radius: 9999px; // large enough to make it look like a circle
  padding: 0.5rem 1rem;
}

@mixin secondary-btn {
  background-color: white;
  color: $primary-500;
  border: 1px solid $primary-500;
  &:hover {
    background-color: $primary-100;
    color: white;
  }
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 9999px; // large enough to make it look like a circle
  padding: 0.5rem 1rem;
}

@mixin tertiary-btn {
  background-color: white;
  color: $primary-500;
  border: 1px solid $primary-500;
  &hover {
    background-color: orange;
    border-color: orange;
    color: white;
  }
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 9999px; // large enough to make it look like a circle
  padding: 0.5rem 1rem;
}

@mixin default-btn-styles {
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  outline: none;
  border-radius: 9999px; // large enough to make it look like a circle
  padding: 0.5rem 1rem;
}

.my-primary-button {
  @include primary-btn;
}

.my-secondary-button {
  @include secondary-btn;
}

.my-tertiary-button {
  @include tertiary-btn;
}

.my-default-button {
  @include default-btn-styles;
}

@layer base {
  html {
    font-family: "Poppins", sans-serif;
    font-size: 88%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.go4109123758 {
  z-index: 999;
}

/* .react-pdf__Page__canvas {
  margin-bottom: 1rem;
} */

@layer utilities {
  .primary-btn {
    @apply bg-primary-500 hover:bg-primary-100 transition-colors outline-none duration-300 text-white py-2 px-4 rounded-full;
  }
  .secondary-btn {
    @apply bg-white hover:bg-primary-100 hover:text-white transition-colors outline-none duration-300 text-primary-500 border border-primary-500 py-2 px-4 rounded-full;
  }
  .teritary-btn {
    @apply bg-white hover:bg-[#F59A1E] hover:text-white transition-colors outline-none duration-300 text-[#F59A1E] border border-[#F59A1E] hover:border-orange-200 py-2 px-4 rounded-full;
  }
  .default-btn-styles {
    @apply transition-colors duration-300 py-2 px-4 rounded-full outline-none;
  }
}

::-webkit-scrollbar {
  background: #1157da5d;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #002569;
  border-radius: 6px;
}

/* @media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
} */

*::-webkit-scrollbar {
  display: none;
}

/* .homepage::-webkit-scrollbar {
  display: none;
} */

.Series g g path {
  stroke: #135ade !important;
}

/* loading spinner styles */
.spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #4f80db; */
  background: #555;
}

/* ezdrawer styles */
.EZDrawer__container {
  z-index: 45 !important;
}
.EZDrawer__overlay {
  z-index: 44 !important;
}
