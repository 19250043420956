.heading {
    color: var(--colors-text-text-primary-900, #101828);

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.loader {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light color for the spinner */
    border-top: 8px solid #fff; /* White color for the spinner */
    border-radius: 50%;
    width: 60px; /* Adjust the size of the spinner */
    height: 60px; /* Adjust the size of the spinner */
    animation: spin 1s linear infinite; /* Spinner animation */
    margin: 0 auto; /* Center the spinner */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}